import logo from './logo.svg';
import './App.css';

import { BrowserRouter,Routes,Route } from 'react-router-dom'
import Sliders from './component/Sliders';
import Last from './component/Last';
import Failed from './component/Failed';
import 'bootstrap/dist/css/bootstrap.min.css'; 

function App() {
  return (
    
<BrowserRouter>
    
    <Routes>
      <Route path=':encrypt_id'  inex element={<Sliders/>}/>
      <Route path='/Last'  inex element={<Last/>}/>
      <Route path='/Failed'  inex element={<Failed/>}/>
      {/* <Route path='*' element={<NotFound/>}/> */}
    </Routes>

    </BrowserRouter>
  );
}

export default App;
