import React,{useState,useEffect} from 'react'
import "./slider.css";
import './form.css';    
import { useNavigate } from 'react-router-dom';
import { apiRequestPOST } from '../utils/ApiCall';
import { BASE_URL } from '../utils/Constants';
import { BeatLoader } from 'react-spinners';
import { Alert } from 'bootstrap';


const Sliders = () => {
   
    const[index,setIndex] = useState(0);
    const [loading, setLoading] = useState(true);

     const navigate = useNavigate();


     const [bookData, setBookData] = useState([]);

     
     const onAnswerPush = (e) => {
      let _bookData = { ...bookData };  
      _bookData['answer_check'] = e;  
      setBookData(_bookData);  
    };
    

     const onInputChange = (e, name) => {    
       const val = e; 
       let _bookData = {...bookData};
       _bookData[`${name}`] = val;
       setBookData(_bookData); 
     };
     const onInputChangeTwo = (e, name,e1, name1) => {  
      let _bookData = {...bookData};
      _bookData[`${name}`] = e;
      _bookData[`${name1}`] = e1;
      setBookData(_bookData); 
    }; 
    const onInputChangeThree = (e, name,e1, name1,e2, name2) => {  
     let _bookData = {...bookData};
     _bookData[`${name}`] = e;
     _bookData[`${name1}`] = e1;
     _bookData[`${name2}`] = e2;
     setBookData(_bookData); 
   };
    
    const onCheckChange = (option,val,id,e, name,e1, name1) => {  
      let _bookData = {...bookData};
      _bookData[`${name}`] = e;
      _bookData[`${name1}`] = e1;
      setBookData(_bookData);  
    }; 
            
const clear_bookData = async() => {     
  setBookData([]); 
  setValidationSchema(false);  
}        
    const [validationSchema, setValidationSchema] = useState(false);
    const Validation_schema = () => {   
       bookData.answer_check
        ?   buttonsubmit()
        : setValidationSchema(true) 
    };

    const buttonsubmit = async() => {
      setValidationSchema(false);  

      let _bookData = {...bookData};  
      let _answer = [...bookData?.answer]; 
      _answer.push(bookData?.answer_check);   
      _bookData['answer'] = _answer;    
      _bookData['answer_check'] = '';  
      setBookData(_bookData); 

       index === (questionnaireList.length - 1) ? completeButton() : setIndex(index + 1);
    };

    const completeButton = async() => {  
      setLoading(true);
      setIndex(index + 1)
      localStorage.setItem('bookData',JSON.stringify(bookData))  
      
     await apiRequestPOST(`${BASE_URL}app_ctrl/add_questionnaire_lead`, bookData, false)
      .then(async (response) => {   
        setLoading(false);
        if (response.status == true) {  
          navigate('/Last');
        }else{
          navigate('/Failed');
        }
      }, (error) => {
       alert('Failed,Try Again') ;
       navigate('/Failed');
      });   
    }

     const [questionnaireList, setquestionnaireList] = useState([]); 
     useEffect(() => {  

      const pathname = window.location.pathname;
    const encrypt_id = pathname.substring(pathname.lastIndexOf('/') + 1); 

      let _bookData = { ...bookData }; 
      _bookData['encrypt_id'] = encrypt_id;
      _bookData['answer'] = []; 
      _bookData['answer_check'] = ''; 
      setBookData(_bookData); 
   

      
  apiRequestPOST(`${BASE_URL}app_ctrl/get_questionnaire_lead`, {'encrypt_id':encrypt_id}, false)
  .then((response) => {
    setLoading(false);
    if (response.status == true) {  
      setquestionnaireList(response.data)
    }else{
      navigate('/Failed');
    }
  }, (error) => {
     console.log(error);
  }); 
      
    }, []);
 
  return (
        
    
    <div className='body'>
    
    <div className='form'>
       {/* <h4 className='hea'>Shapemission</h4> */}
       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <img src={`${BASE_URL}app_slider/logo.svg`} alt="Shapemission" />
      </div>
       <div className='content'>
        
       {loading ? (
        <div className="loader">
          <BeatLoader color="#000" loading={loading} />
        </div>
      ) : (
        <div className='item'>
        
          {questionnaireList.map((question,indexPeople) => {
        
           
            return(
              <>
              {index==indexPeople&&
              < > 
            
                
                <table style={{width: '100%'}} className='item'>
      <thead>
        <tr><th >{indexPeople + 1 +'.   '+question}</th></tr>
                    
                    <tr>
                      <td> 
                        <textarea 
        style={{
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '4px',
          fontSize: '16px',
          width: '100%',
          height: '150px',
          resize: 'none',
          verticalAlign: 'top' // Align text at the top
        }}
        onChange={(event) => onAnswerPush(event.target.value)}  />
                        </td>
                     </tr>
                   

                    <tr>
                      <td colSpan={2}> <br/>
                      {validationSchema && !bookData.answer_check && <span id="error" >*Required</span>} </td>
                      </tr> 
                     
                    </thead>
                  </table> <br></br>
                  
               
              
               
                <div>
             
               <div className='bu'>
               {/* <button className='prev' id='button1' onClick={() => setIndex(index - 1)}>
            <i className=''>Pre</i>
          </button >  */}
      {index!=(questionnaireList.length)&&<button className='button1' onClick={Validation_schema}> Next</button>} 
               </div>
                </div> 
              </>
          }
          </>
            )
              
              
            

          })} 
         </div>
         )}

         </div>
        </div>
      </div>
    
  );
};

export default Sliders
