import React from 'react'
import './last.css';
import { Link,useNavigate } from 'react-router-dom' 

const Last = () => {

  const navigate = useNavigate();
  const Validation_schema = () => {      
localStorage.removeItem('bookData');
window.location.href = 'https://shapemission.com/'; 
  };
  return (
    <div className='last_page'>
      <div class="js-container container" ></div>

  <div className='mains'>
    <div class="checkmark-circle">
      <div class="background" style={{background:'red'}}></div>
      <div class="checkmark draw"></div>
    </div>
    <h1 className='white'>Failed!</h1>
    {/* <p className='white'>You are all set. Well done!</p> */}
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <button className='button1' onClick={Validation_schema}>Ok</button>
    </div>

    {/* <button class="submit-btn" type="submit" onclick="alert('🥺🥺🥺🥺🥺\n Oh no you didn\'t!!!!!!!');">Continue</button> */}
  </div>  

  


      
    </div>
  )
}

export default Last
